import React from "react";
import { BsDownload } from "react-icons/bs";
import { objectArrayToCSVArray } from "../../../utilities/dataHelpers";

const DownloadCSV: React.FC<{ data: any[]; filename: string }> = ({
  data,
  filename,
}) => {
  const rows = objectArrayToCSVArray(data);

  const downloadCSV = (rows: any[]) => {
    let csvContent =
      "data:text/csv;charset=utf-8," +
      rows.map((e: any) => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <button
      onClick={() => downloadCSV(rows)}
      className="flex flex-row items-center shadow click: p-2 rounded-lg bg-site-primary text-white"
    >
      Download CSV <BsDownload className="ml-2" size={20} />
    </button>
  );
};

export default DownloadCSV;
