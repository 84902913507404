import React from "react";

// Packages
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

// Components
import Container from "../Container";

type PaginationProps = {
  changePage: Function;
  currentPage: number;
  totalPages: number;
};

const PaginationControls: React.FC<PaginationProps> = ({
  changePage,
  currentPage,
  totalPages,
}) => {
  return (
    <Container direction="row" items="center" width="1">
      <BsChevronLeft
        className="p-1 m-1 text-3xl font-bold cursor-pointer border rounded-sm hover:bg-[#FF3F55] hover:text-white"
        onClick={() => changePage("previous")}
      />
      <p className="text-sm w-16 text-center">
        {currentPage} / {totalPages}
      </p>
      <BsChevronRight
        className="p-1 m-1 text-3xl font-bold cursor-pointer border rounded-sm hover:bg-[#FF3F55] hover:text-white"
        onClick={() => changePage("next")}
      />
    </Container>
  );
};

export default PaginationControls;
