import moment from "moment";
import { Collection, CollectionData } from "../models/DataContext";

// Utilities
import {
  formatDataValue,
  formatDataKey,
  DataKey,
} from "../components/shared/KeyValueDisplay/KeyValueDisplay";

const manaConversion = 10 ** 18;

export const convertToMana = (num: number) => {
  return num / manaConversion;
};

export const objectArrayToCSVArray = (array: Object[]) => {
  const csvArray: string[][] = [];

  const firstRowKeys = Object.keys(array[0]);

  // Push Top Level Keys
  csvArray.push(firstRowKeys.map((key) => formatDataKey(key as DataKey)));

  array.forEach((object) => {
    csvArray.push(
      Object.values(object).map((value, i) => {
        return formatDataValue(firstRowKeys[i] as DataKey, value);
      })
    );
  });

  return csvArray;
};

export const uniqueCreatorAddresses = (arr: CollectionData[]): string[] => {
  return Array.from(
    new Set(
      arr?.map((element) => {
        return element.creator;
      })
    )
  );
};

export const sumArray = (array: number[]) => {
  let total = 0;

  array.forEach((number) => {
    total = total + number;
  });

  return total;
};

export const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const onlyCreatorCollections = (
  collections: Collection[],
  userId: string
) => {
  return collections.filter((collection) => collection.creator === userId);
};

export const uniqueCollectionsPerCreator = (collections: CollectionData[]) => {
  const creatorTotals = {} as any;
  const creatorTotalData = [] as any;

  if (collections?.length) {
    collections.forEach((collection) => {
      if (creatorTotals[collection.creator]?.collectionCount) {
        creatorTotals[collection.creator].collectionCount =
          creatorTotals[collection.creator].collectionCount + 1;
      } else {
        creatorTotals[collection.creator] = { collectionCount: 1 };
      }
    });

    collections.forEach((collection) => {
      if (creatorTotals[collection.creator].itemsCount) {
        creatorTotals[collection.creator].itemsCount =
          creatorTotals[collection.creator].itemsCount + collection.itemsCount;
      } else {
        creatorTotals[collection.creator].itemsCount = collection.itemsCount;
      }
    });
  }

  const totals: any = Object.values(creatorTotals).reduce(
    (acc: any, curr: any) => {
      return (
        acc[curr.collectionCount]
          ? ++acc[curr.collectionCount]
          : (acc[curr.collectionCount] = 1),
        acc
      );
    },
    {}
  );

  Object.keys(totals).forEach((value: any) => {
    creatorTotalData.push({
      Collections: parseInt(value, 10),
      Creators: totals[value],
    });
  });

  return { creatorTotals, creatorTotalData };
};

export const paginate = (
  array: any[],
  page_size: number,
  page_number: number
) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const abridgeId = (id: string, maxChar: number = 20): string => {
  return id?.length > maxChar ? id.slice(0, maxChar) + "..." : id;
};

export const dateFromSecondsString = (seconds: string) => {
  const valueNum = parseInt(seconds, 10);

  return moment(new Date(valueNum * 1000)).format("MM/DD/YY");
};

export const toCapitalizedWords = (name: string) => {
  var words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export const filterMarket = (sales: any, market: "mint" | "order") => {
  return sales.filter((sale: any) => sale.type === market);
};

export const onlyUnique = (value: any, index: any, self: any) => {
  return self.indexOf(value) === index;
};
