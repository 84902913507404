import { gql } from "@apollo/client";

// MODELS

const SALES_DATA = gql`
  fragment SalesData on Sale {
    id
    type
    buyer
    seller
    price
    item {
      id
      metadata {
        wearable {
          name
        }
      }
      collection {
        id
        name
      }
    }
    timestamp
    txHash
    searchItemId
    searchTokenId
  }
`;

// GraphQl Queries
const GET_ALL_COLLECTIONS = gql`
  query GetCollections($skip: Int) {
    collections(first: 1000, skip: $skip) {
      id
      creator
      name
      owner
      createdAt
      itemsCount
      items {
        id
        image
      }
    }
  }
`;

const GET_COLLECTION_INFO = gql`
  query GetCollectionInfo($id: String) {
    collections(where: { id: $id }) {
      id
      items {
        id
        image
        rarity
      }
      owner
      creator
      name
      symbol
      isCompleted
      isApproved
      isEditable
      minters
      managers
      urn
      itemsCount
      createdAt
      updatedAt
      reviewedAt
    }
  }
`;

const GET_ITEM_INFO = gql`
  query GetItemInfo($id: String) {
    items(where: { id: $id }) {
      id
      blockchainId
      itemType
      totalSupply
      maxSupply
      rarity
      creationFee
      available
      price
      beneficiary
      contentHash
      URI
      minters
      managers
      urn
      createdAt
      updatedAt
      reviewedAt
      metadata {
        wearable {
          name
        }
      }
    }
  }
`;

const GET_DATA_COUNTS = gql`
  query GetCounts {
    counts {
      id
      orderTotal
      bidTotal
      collectionTotal
      itemTotal
      nftTotal
      salesTotal
      salesManaTotal
      primarySalesTotal
      primarySalesManaTotal
      secondarySalesTotal
      secondarySalesManaTotal
      royaltiesManaTotal
    }
  }
`;

const GET_COLLECTION_SALES = gql`
  ${SALES_DATA}
  query GetCollectionSales($address: String) {
    sales(first: 1000, where: { searchContractAddress: $address }) {
      ...SalesData
    }
  }
`;

const GET_CREATOR_SALES = gql`
  ${SALES_DATA}
  query GetCreatorSales($sellerid: String) {
    sales(first: 1000, where: { seller: $sellerid }) {
      ...SalesData
    }
  }
`;

const GET_ITEM_SALES = gql`
  ${SALES_DATA}
  query GetItemSales($id: String) {
    sales(first: 1000, where: { item: $id }) {
      ...SalesData
    }
  }
`;

export {
  GET_ALL_COLLECTIONS,
  GET_DATA_COUNTS,
  GET_COLLECTION_INFO,
  GET_COLLECTION_SALES,
  GET_ITEM_SALES,
  GET_ITEM_INFO,
  GET_CREATOR_SALES,
};
