import React from "react";

// Utilities
import {
  convertToMana,
  dateFromSecondsString,
  toCapitalizedWords,
} from "../../../utilities/dataHelpers";
import { capitalizeFirstLetter } from "../../../utilities/general";

import Container from "../Container";
import { ContainerProps } from "../Container/Container";

// Components

interface KeyValueProps {
  data: any;
  title?: string;
  hiddenDataPoints?: string[];
  containerProps?: Partial<ContainerProps>;
}

const KeyValueDisplay: React.FC<KeyValueProps> = ({
  data = {},
  hiddenDataPoints = [],
  title = "",
  containerProps,
}) => {
  return (
    <Container
      title={title}
      width="1"
      direction="col"
      items="center"
      {...containerProps}
    >
      {Object.keys(data).map((dataPoint, i) => {
        if (!hiddenDataPoints.includes(dataPoint)) {
          return (
            <div key={i} className="flex flex-row w-full text-left p-1 text-sm">
              <p className="font-bold">{formatDataKey(dataPoint as DataKey)}</p>
              :
              <p className="flex flex-1 ml-2">
                {formatDataValue(dataPoint as DataKey, data[dataPoint])}
              </p>
            </div>
          );
        }
        return <React.Fragment key={i}></React.Fragment>;
      })}
    </Container>
  );
};

export default KeyValueDisplay;

export type DataKey =
  | "id"
  | "rarity"
  | "creationFee"
  | "price"
  | "beneficiary"
  | "minters"
  | "managers"
  | "createdAt"
  | "updatedAt"
  | "reviewedAt"
  | "primarySales"
  | "secondarySales"
  | "item"
  | "totalCreatorRoyalties"
  | "timestamp";

export const formatDataValue = (dataKey: DataKey, dataValue: any) => {
  const trimmedDataValue = JSON.stringify(dataValue).replace(/['"]+/g, "");

  switch (dataKey) {
    case "createdAt":
    case "reviewedAt":
    case "updatedAt":
    case "timestamp":
      return dateFromSecondsString(dataValue);
    case "creationFee":
    case "price":
      return `${convertToMana(dataValue)} MANA`;
    case "primarySales":
    case "secondarySales":
    case "totalCreatorRoyalties":
      return `${dataValue} MANA`;
    case "item":
      return trimmedDataValue.replace(/,/g, "-");
    default:
      return capitalizeFirstLetter(trimmedDataValue);
  }
};

export const formatDataKey = (dataKey: DataKey) => {
  return toCapitalizedWords(dataKey);
};
