import React, { useEffect, useState } from "react";

// Libraries
import { BiCopy } from "react-icons/bi";
import { BsChevronCompactDown } from "react-icons/bs";
import { IconType } from "react-icons/lib";

// Shared Componenets
import Container from "../../components/shared/Container";

const ToastContext = React.createContext<ToastContextData>(
  {} as ToastContextData
);

const ToastProvider: React.FC = ({ children }) => {
  const [toastNotification, setToastNotification] =
    useState<ToastNotification | null>(null);

  useEffect(() => {
    if (toastNotification) {
      setTimeout(() => {
        setToastNotification(null);
      }, (toastNotification.duration || 3) * 1000);
    }
  }, [toastNotification]);

  const newToastNotification = (type: "copied") => {
    switch (type) {
      case "copied":
        setToastNotification(notifications.copied);
        break;
    }
  };

  return (
    <ToastContext.Provider value={{ toastNotification, newToastNotification }}>
      {toastNotification && (
        <ToastNotification notification={toastNotification} />
      )}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

export const useToast = () => {
  const context = React.useContext(ToastContext);

  return context;
};

const ToastNotification: React.FC<{ notification: ToastNotification }> = ({
  notification,
}) => {
  const Icon = notification.icon;

  return (
    <Container
      width="1/5"
      direction="row"
      items="center"
      position="fixed"
      additionalClasses={[`bg-emerald-400 bottom-1 left-1 text-center`]}
    >
      <Icon size={30} className="text-white" />
      <p className="font-semibold text-white">{notification.body}</p>
      <BsChevronCompactDown className="ml-auto mr-2 text-white" size={20} />
    </Container>
  );
};

const notifications = {
  copied: {
    body: "Copied to Clipboard",
    icon: BiCopy,
    duration: 3,
    color: "green-300",
  },
};

type ToastContextData = {
  toastNotification: ToastNotification | null;
  newToastNotification: Function;
};

interface ToastNotification {
  body: string;
  icon: IconType;
  color: string;
  duration?: number;
}
