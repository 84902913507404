import React from "react";

import { motion } from "framer-motion";

interface ProgressBarProps {
  currentValue: string;
  maxValue: string;
  units?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentValue,
  maxValue,
  units,
}) => {
  const width = (parseInt(currentValue, 10) / parseInt(maxValue, 10)) * 100;

  return (
    <div className="w-full flex flex-row items-center h-10">
      <div className="relative w-full h-full shadow rounded-md flex flex-row items-center justify-center">
        <p
          className={`z-20 absolute text-center flex flex-row items-center justify-center bg-white p-1 rounded-sm shadow`}
        >{`${currentValue} / ${maxValue} ${units && units}`}</p>
        <div
          className={`absolute top-0 z-0 left-0 w-full h-full bg-gray-100 rounded-md`}
        ></div>
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${width}%` }}
          className={`absolute z-10 top-0 left-0 h-full bg-site-primary flex flex-row items-center rounded-md`}
        ></motion.div>
      </div>
    </div>
  );
};

export default ProgressBar;
