import React from "react";
import { Width } from "../../../models/Shared";

const TextInput: React.FC<TextInputProps> = ({
  title,
  placeholder,
  middleware,
  setState,
  width,
  secure,
  onEnter,
  error,
  ...props
}) => {
  const type: TextInputType = secure ? "password" : "text";

  // If middleware is passed, we must pass the value through middleware and set state to corresponding value
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (middleware) {
      value = middleware(value);
    }

    setState(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && onEnter) {
      onEnter();
    }
  };

  return (
    <div>
      {title && <label>{title}</label>}
      <input
        className={`border-2 rounded-md px-2 py-1 outline-none hover:cursor-pointer ${
          error ? "border-site-primary" : ""
        }`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        type={type}
        placeholder={placeholder}
        width={width}
        {...props}
      />
    </div>
  );
};

export default TextInput;

// Text Input
export interface TextInputStyleProps {
  width: Width;
}

export interface TextInputProps extends TextInputStyleProps {
  title?: string;
  placeholder?: string;
  middleware?: Function | undefined;
  setState: Function;
  secure?: boolean;
  onEnter: Function;
  error?: boolean | undefined;
}

type TextInputType = "password" | "text";
