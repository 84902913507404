import React from "react";
import { useNavigate } from "react-router-dom";

const SiteLogo = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate("/");
      }}
      className="w-full flex flex-row justify-start items-center"
    >
      <img src={logoSVG} alt="decentraland_logo" />
      <h1 className="lg:text-2xl font-medium ml-2 hover:cursor-pointer md:text-lg block sm:text-left  ">
        Wearables Analytics
      </h1>
    </div>
  );
};

export default SiteLogo;

const logoSVG = `data:image/svg+xml;charset=utf-8,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='85.355%25' y1='14.645%25' x2='14.645%25' y2='85.355%25' id='a'%3E%3Cstop stop-color='%23FF2D55' offset='0%25'/%3E%3Cstop stop-color='%23FFBC5B' offset='100%25'/%3E%3C/linearGradient%3E%3ClinearGradient x1='49.966%25' y1='0%25' x2='49.966%25' y2='100%25' id='b'%3E%3Cstop stop-color='%23A524B3' offset='0%25'/%3E%3Cstop stop-color='%23FF2D55' offset='100%25'/%3E%3C/linearGradient%3E%3ClinearGradient x1='49.966%25' y1='0%25' x2='49.966%25' y2='100%25' id='c'%3E%3Cstop stop-color='%23A524B3' offset='0%25'/%3E%3Cstop stop-color='%23FF2D55' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='url(%23a)' cx='18' cy='18' r='18'/%3E%3Cpath fill='url(%23b)' d='M11.313 0v13.5h11.25z' transform='translate(1.44 11.7)'/%3E%3Cpath fill='%23FFF' d='M1.503 25.2h11.25V11.7z'/%3E%3Cpath d='M7.2 32.4A17.925 17.925 0 0018 36c4.05 0 7.794-1.341 10.8-3.6H7.2z' fill='%23FF2D55'/%3E%3Cpath d='M3.6 28.8a18.435 18.435 0 003.6 3.6h21.6a18.435 18.435 0 003.6-3.6H3.6z' fill='%23FC9965'/%3E%3Cpath d='M24.147 25.2H1.503A17.923 17.923 0 003.6 28.8h20.556v-3.6h-.009z' fill='%23FFBC5B'/%3E%3Cpath fill='url(%23c)' d='M8.307 0v9.9h8.253z' transform='translate(15.84 18.9)'/%3E%3Cpath fill='%23FFF' d='M15.903 28.8h8.244v-9.9z'/%3E%3Ccircle fill='%23FFC95B' cx='24.147' cy='11.7' r='4.5'/%3E%3Ccircle fill='%23FFC95B' cx='12.753' cy='6.75' r='2.25'/%3E%3C/g%3E%3C/svg%3E`;
