import React, { useState } from "react";

// Packages
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";

// Shared Comps
import Container from "../Container";

// Contexts
import { useAnalyticsData } from "../../../contexts/AnalyticsData/AnalyticsProvider";
import { uniqueCollectionsPerCreator } from "../../../utilities/dataHelpers";
import { CreatorData } from "../../Pages/Home/PaginatedCreators";

const CustomToolTip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ background: "#CBCBCB", padding: "3px", borderRadius: "3px" }}
      >
        <p className="label">{`${payload[0].payload.Creators} Creator(s) made ${payload[0].payload.Collections} Collection(s)`}</p>
      </div>
    );
  }

  return null;
};

const CollectionsPerCreator: React.FC = () => {
  const { collections } = useAnalyticsData();
  const [numOfCollections, setNumberOfCollections] = useState<number>(0);

  const ClickHandler = (e: any) => {
    if (e?.activeLabel) return setNumberOfCollections(e?.activeLabel);
  };

  return (
    <Container
      width="1"
      direction="col"
      title="Collections Per Creator"
      items="center"
      additionalClasses={["min-h-[500px]"]}
    >
      <ResponsiveContainer width={"90%"} height={550}>
        <BarChart
          data={
            uniqueCollectionsPerCreator(collections?.data?.collections)
              .creatorTotalData
          }
          margin={{ bottom: 15, left: 15, top: 15, right: 15 }}
          onClick={ClickHandler}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis interval="preserveStart" dataKey="Collections" type="number">
            <Label
              value="Number of Collections"
              offset={-0}
              position="bottom"
            />
          </XAxis>
          <YAxis dataKey="Creators" scale="sqrt">
            <Label
              value="Number of Creators"
              angle={-90}
              offset={5}
              position="insideLeft"
            />
          </YAxis>
          <Tooltip content={<CustomToolTip />} />
          <Bar dataKey="Creators" fill="#FF3F55" />
        </BarChart>
      </ResponsiveContainer>
      {numOfCollections ? (
        <Container
          width={"1"}
          items="center"
          justify="start"
          title={`Creators that made ${numOfCollections} Collections`}
          direction="col"
          style={{ marginTop: "20px" }}
        >
          <table className="w-full border rounded-sm mt-2 table-auto">
            <thead>
              <tr className="border font-bold">
                <th className="py-3">Creator Name</th>
                <th>Address</th>
                <th>Items</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(
                uniqueCollectionsPerCreator(collections?.data?.collections)
                  .creatorTotals
              )
                .filter((a: any) => {
                  return a[1].collectionCount == numOfCollections;
                })
                .map((creator: any) => {
                  return (
                    <CreatorData
                      noItems={false}
                      key={creator[0]}
                      creator={creator[0]}
                      collectionRow={false}
                      maxAdressLen={30}
                    />
                  );
                })}
            </tbody>
          </table>
        </Container>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default CollectionsPerCreator;
