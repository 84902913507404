// Utilities
import { decentralandPeerUrl } from "../../utilities/urls";

// models
import { Creator } from "../../models/DataContext";

export const fetchCreatorInformation = async (
  address: string
): Promise<Creator> => {
  const result = await fetch(
    `${decentralandPeerUrl}/lambdas/profile/${address}`,
    {
      method: "GET",
    }
  );

  return await result.json();
};
