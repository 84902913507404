import React from "react";

// Packages
import CountUp from "react-countup";

// Contexts
import { useAnalyticsData } from "../../../../contexts/AnalyticsData/AnalyticsProvider";

// Utilities
import { uniqueCreatorAddresses } from "../../../../utilities/dataHelpers";

// Shared Components
import Container from "../../../shared/Container";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import { Loader } from "../../../shared/LoadingWrapper/LoadingWrapper";

// Provides user with quick access to raw data stats
const DataSnapshot: React.FC<any> = () => {
  const analytics = useAnalyticsData();

  const { data: countsData } = analytics.counts;

  const { loading: collectionsLoading, data: collectionsData } =
    analytics.collections;

  const { collectionsLoaded } = analytics;

  return (
    <LoadingWrapper loading={collectionsLoading}>
      {countsData && countsData.counts ? (
        <Container
          width="1"
          direction="col"
          additionalClasses={["mx-2"]}
          items="center"
        >
          <Container width="1" direction={"col"}>
            <Container width="1" direction="row" items="center">
              <Container items="center" border direction={"col"} width="1">
                <h2 className="font-semibold text-lg">Creators:</h2>
                <p className="italic text-lg">
                  {collectionsLoaded ? (
                    <CountUp
                      duration={0.5}
                      separator=","
                      end={
                        uniqueCreatorAddresses(collectionsData.collections)
                          .length
                      }
                    />
                  ) : (
                    <Loader size={20} />
                  )}
                </p>
              </Container>

              <Container items="center" border direction={"col"} width="1">
                <h2 className="font-semibold text-lg">Collections:</h2>
                <p className="italic text-lg">
                  {
                    <CountUp
                      duration={0.5}
                      separator=","
                      end={countsData.counts[0].collectionTotal}
                    />
                  }
                </p>
              </Container>

              <Container items="center" border direction={"col"} width="1">
                <h2 className="font-semibold text-lg">Items:</h2>
                <p className="italic text-lg">
                  {
                    <CountUp
                      duration={0.5}
                      separator=","
                      end={countsData.counts[0].itemTotal}
                    />
                  }
                </p>
              </Container>
            </Container>
            <Container width="1" direction="row" items="center">
              <Container items="center" border direction={"col"} width="1">
                <h2 className="font-semibold text-lg">Total Sales:</h2>
                <p className="italic text-lg">
                  {
                    <CountUp
                      duration={0.5}
                      separator=","
                      end={countsData.counts[0].salesTotal}
                    />
                  }
                </p>
              </Container>

              <Container items="center" border direction={"col"} width="1">
                <h2 className="font-semibold text-lg">Primary Sales:</h2>
                <p className="italic text-lg">
                  {
                    <CountUp
                      duration={0.5}
                      separator=","
                      end={countsData.counts[0].primarySalesTotal}
                    />
                  }
                </p>
              </Container>

              <Container items="center" border direction={"col"} width="1">
                <h2 className="font-semibold text-lg">Secondary Sales:</h2>
                <p className="italic text-lg">
                  {
                    <CountUp
                      duration={0.5}
                      separator=","
                      end={countsData.counts[0].secondarySalesTotal}
                    />
                  }
                </p>
              </Container>
            </Container>
          </Container>
        </Container>
      ) : null}
    </LoadingWrapper>
  );
};

export default DataSnapshot;
