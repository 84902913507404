import React from "react";

// Shared
import Container from "../Container";

// Static
import logo from "../../../static/atlascorp.png";

const Footer = () => {
  return (
    <Container width="1" justify="end" items="center">
      <p>Developed in 2022 by </p>
      <a
        rel="noreferrer"
        className="flex flex-row justify-end items-center"
        href="https://discord.gg/84GEXy3UxM"
        target="_blank"
      >
        <img className="w-40 ml-1 " src={logo} alt="Atlas Corp Logo" />
      </a>
    </Container>
  );
};

export default Footer;
