import React, { useState } from "react";
import { BiCollapse, BiExpand } from "react-icons/bi";

// Models
import {
  Alignment,
  Border,
  Direction,
  Justify,
  Position,
  Width,
} from "../../../models/Shared";

export interface ContainerProps extends ContentProps {
  width: Width;
  title?: string;
  border?: Border;
  wrap?: boolean;
  section?: boolean;
  additionalClasses?: string[];
  position?: Position;
  shadow?: boolean;
}

export interface ContentProps {
  children?: React.ReactChild | React.ReactChild[];
  direction?: Direction;
  justify?: Justify;
  items?: Alignment;
  collapsable?: boolean;
  style?: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = ({
  children,
  width,
  border,
  items = "start",
  direction = "row",
  justify = "center",
  title,
  wrap,
  section,
  additionalClasses,
  position,
  shadow,
  collapsable,
  ...restProps
}) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      {...restProps}
      className={`p-2 bg-white ${
        shadow && "shadow"
      } mx-1 my-2 rounded-sm flex flex-${direction} ${position} items-${items} items justify-${justify} min-h-max ${generateClassNameFromProps(
        width,
        border,
        wrap,
        collapsed,
        additionalClasses
      )}`}
    >
      <p
        onClick={
          collapsable
            ? () => {
                setCollapsed((prev) => !prev);
              }
            : () => null
        }
        className={`font-bold text-${collapsed ? "xs" : "xl"} ${
          collapsable && "cursor-pointer hover:underline"
        } flex flex-row items-center`}
      >
        {title}{" "}
        {collapsable ? (
          collapsed ? (
            <BiExpand className="ml-2" size={15} />
          ) : (
            <BiCollapse className="ml-2" size={15} />
          )
        ) : null}
      </p>
      {!collapsed ? children : <></>}
    </div>
  );
};

export default Container;

const generateClassNameFromProps = (
  width: Width,
  border?: Border,
  wrap?: boolean,
  collapsed?: boolean,
  additionalClasses: string[] = []
) => {
  const className: string[] = [];

  if (!collapsed) {
    switch (width) {
      case "custom":
        break;
      case "1":
        className.push("w-full");
        break;
      case "1/2":
        className.push("w-1/2");
        break;
      case "1/3":
        className.push("w-1/3");
        break;
      case "1/4":
        className.push("w-1/4");
        break;
      case "1/5":
        className.push("w-1/5");
        break;
      case "2/3":
        className.push("w-2/3");
        break;
      case "3/4":
        className.push("w-3/4");
        break;
      default:
        className.push("w-full");
        break;
    }
  } else {
    className.push("flex flex-1");
  }

  if (border || collapsed) {
    className.push("border");
  }

  if (wrap) {
    className.push("flex-wrap");
  }

  if (additionalClasses.length) {
    additionalClasses.forEach((additionalClass) =>
      className.push(additionalClass)
    );
  }

  return className.join(" ");
};
