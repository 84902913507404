import React, { useEffect, useState } from "react";

// Packages
import moment from "moment";
import { OperationVariables, QueryResult, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

// Utilities
import {
  abridgeId,
  convertToMana,
  filterMarket,
  onlyUnique,
  paginate,
  sumArray,
} from "../../../utilities/dataHelpers";
import { usePage } from "../../../utilities/hooks";

// API
import {
  GET_COLLECTION_INFO,
  GET_COLLECTION_SALES,
  GET_ITEM_INFO,
} from "../../../api/graphql/decentraland";

// Shared Components
import Container from "../../shared/Container";
import { Loader } from "../../shared/LoadingWrapper/LoadingWrapper";
import KeyValueDisplay from "../../shared/KeyValueDisplay";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

// Models
import { Collection, Item, Sale } from "../../../models/DataContext";

// Hooks
import { useAnalyticsData } from "../../../contexts/AnalyticsData/AnalyticsProvider";
import ToggleSwitch from "../../shared/ToggleSwitch";
import PaginationControls from "../../shared/PaginationControls";
import DownloadCSV from "../../shared/DownloadCSV/DownloadCSV";

const ExpandedCollection: React.FC = () => {
  const [selectedItemId, setSelectedItemId] = useState<string>("");

  const { id, itemid } = useParams();

  const navigate = useNavigate();

  const {
    data,
    loading: infoLoading,
    error: infoError,
  } = useQuery(GET_COLLECTION_INFO, {
    variables: { id },
  });

  const {
    data: salesData,
    loading: salesLoading,
    error: salesError,
  } = useQuery(GET_COLLECTION_SALES, {
    variables: { address: id },
  });

  const marketSales = {
    primarySales: !salesLoading
      ? sumArray(
          salesData.sales
            .filter((sale: Sale) => sale.type === "mint")
            .map((sale: Sale) => convertToMana(sale.price))
        )
      : 0,
    secondarySales: !salesLoading
      ? sumArray(
          salesData.sales
            .filter((sale: Sale) => sale.type === "order")
            .map((sale: Sale) => convertToMana(sale.price))
        )
      : 0,
    get totalCreatorRoyalties() {
      return (this.secondarySales * 0.025).toFixed(2);
    },
  };
  const currentCollection: Collection =
    !infoLoading && !infoError && data.collections[0];

  const selectedItemInfo = useQuery(GET_ITEM_INFO, {
    variables: { id: selectedItemId },
  });

  useEffect(() => {
    if (selectedItemId && currentCollection) {
      navigate(`/collections/${currentCollection.id}/${selectedItemId}`, {
        replace: true,
      });
    }
  }, [selectedItemId]);

  useEffect(() => {
    if (itemid) {
      setSelectedItemId(itemid);
    }
  }, [itemid]);

  useEffect(() => {
    if (!selectedItemId && !infoLoading) {
      setSelectedItemId(currentCollection.items[0].id);
    }
  }, [infoLoading]);

  if (infoError || salesError || selectedItemInfo.error) {
    return <div>{infoError || salesError || selectedItemInfo.error}</div>;
  }

  return (
    <>
      {!salesLoading &&
      !infoLoading &&
      !selectedItemInfo.loading &&
      selectedItemInfo?.data?.items?.length ? (
        <Container
          title={currentCollection.name}
          width="1"
          items="center"
          direction="col"
        >
          <ItemsWithMetadata
            loading={infoLoading}
            error={infoError}
            currentCollection={currentCollection}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
            selectedItemInfo={selectedItemInfo as any}
          />
          <SalesTable
            setSelectedItemId={setSelectedItemId}
            selectedItemId={selectedItemId}
            sales={salesData.sales}
          />
          <KeyValueDisplay
            data={{ ...marketSales, ...currentCollection }}
            title="Collection Metadata"
            hiddenDataPoints={["items", "__typename"]}
            containerProps={{ shadow: true }}
          />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ExpandedCollection;

const ItemsWithMetadata: React.FC<{
  loading: boolean;
  error: string | undefined;
  currentCollection: Collection;
  selectedItemId: string;
  setSelectedItemId: Function;
  selectedItemInfo: QueryResult;
}> = ({
  loading,
  error,
  currentCollection,
  selectedItemId,
  setSelectedItemId,
  selectedItemInfo,
}) => {
  const hiddenKeys = [
    "__typename",
    "metadata",
    "blockchainId",
    "itemType",
    "available",
    "URI",
    "contentHash",
    "urn",
    "maxSupply",
    "totalSupply",
  ];

  return (
    <Container width="1" shadow>
      <Container
        width="1/3"
        direction="col"
        title="Items"
        items="start"
        justify="start"
      >
        <div className="w-full grid-cols-2 grid">
          {!loading && !error ? (
            currentCollection.items.map((item: Item, i: number) => {
              if (item.image) {
                return (
                  <img
                    key={i}
                    alt={item.rarity}
                    className={`${
                      selectedItemId === item.id
                        ? `border-2`
                        : `border-rarity-${item.rarity}`
                    } hover:border-rarity-${
                      item.rarity
                    } cursor-pointer m-1 rounded-md max-w-[140px] max-h-[140px]`}
                    src={item.image}
                    onClick={() => {
                      setSelectedItemId(item.id);
                    }}
                  />
                );
              }
              return <></>;
            })
          ) : (
            <></>
          )}
        </div>
      </Container>
      <Container
        border
        width="2/3"
        direction="col"
        items="center"
        justify="start"
      >
        {selectedItemInfo.data && !selectedItemInfo.loading ? (
          <>
            <p className="font-semibold w-full text-left my-2 underline">
              {selectedItemInfo.data.items[0].metadata.wearable.name}
            </p>
            <ProgressBar
              maxValue={selectedItemInfo.data.items[0].maxSupply}
              currentValue={selectedItemInfo.data.items[0].totalSupply}
              units={"minted"}
            />
            <KeyValueDisplay
              data={selectedItemInfo?.data?.items[0]}
              hiddenDataPoints={hiddenKeys}
            />
          </>
        ) : (
          <></>
        )}
      </Container>
    </Container>
  );
};

const SalesTable: React.FC<{
  sales: Sale[];
  selectedItemId?: string;
  setSelectedItemId: Function;
}> = ({ sales, selectedItemId, setSelectedItemId }) => {
  const [currentMarket, setCurrentMarket] = useState<"mint" | "order">("mint");

  const [viewAll, setViewAll] = useState(false);

  const items = sales.map((sale) => sale.item).filter(onlyUnique);

  const itemSales =
    viewAll || items.filter((item) => item.id === selectedItemId).length === 0
      ? sales
      : sales.filter((sale) => sale.item.id === selectedItemId);

  const currentSales = itemSales.length
    ? filterMarket(itemSales, currentMarket)
    : [];

  const { currentPage, changePage, pageSize } = usePage(
    10,
    currentSales.length
  );

  const paginatedSales = sales.length
    ? paginate(currentSales, pageSize, currentPage)
    : [];

  useEffect(() => {
    if (currentSales.length < pageSize) {
      changePage("reset");
    }
  }, [currentSales]);

  useEffect(() => {
    if (currentSales.length === 0) {
      setCurrentMarket((prevMarket) =>
        prevMarket === "mint" ? "order" : "mint"
      );
    }
  }, []);

  if (!sales.length) {
    return <Loader />;
  }

  return (
    <Container
      direction="col"
      title={!viewAll ? "Item Sales History" : "Collection Sales History"}
      width="1"
      shadow
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <ToggleSwitch
            onClick={() => {
              setCurrentMarket((prevMarket) =>
                prevMarket === "mint" ? "order" : "mint"
              );
            }}
            on={currentMarket === "mint"}
            onTitle={"Primary"}
            offTitle={"Secondary"}
          />
          <select
            value={viewAll ? "" : selectedItemId!}
            onChange={(newValue) => {
              if (newValue.target.value === "" && !viewAll) {
                setViewAll(true);
                return;
              }
              setViewAll(false);
              setSelectedItemId(newValue.target.value);
            }}
            className="border px-2 py-1 border-site-primary mx-2"
          >
            <option key="viewAll" value="">
              All Items
            </option>
            {items.reverse().map((item, i) => {
              return (
                <option key={i} value={item.id}>
                  {item.metadata.wearable.name}-{item.id.split("-")[1]}
                </option>
              );
            })}
          </select>
        </div>
        {currentSales.length > 0 && (
          <DownloadCSV
            filename={`${currentMarket}_sales_export_${
              selectedItemId ? selectedItemId : currentSales[0].collection.id
            }`}
            data={
              currentSales && currentSales.length
                ? currentMarket === "order"
                  ? currentSales.map((sale: Sale) => {
                      const newSaleItem: any = Object.assign({}, sale);
                      newSaleItem.item = sale.item?.metadata.wearable.name;

                      return {
                        ...newSaleItem,
                        totalCreatorRoyalties: `${(
                          convertToMana(sale.price) * 0.025
                        ).toFixed(3)}`,
                      };
                    })
                  : currentSales
                : []
            }
          />
        )}
      </div>
      <table className="w-full border rounded-sm mt-2 table-auto">
        <thead className="border font-bold">
          <th>Tx #</th>
          <th className="py-3">Item Name</th>
          <th>Date</th>
          <th>Seller</th>
          <th>Buyer</th>
          <th>Price</th>
          {currentMarket === "order" ? (
            <>
              <th>Royalties</th>
              <th>Type</th>
            </>
          ) : null}
        </thead>
        <tbody>
          {paginatedSales && paginatedSales.length ? (
            <>
              {paginatedSales.map((sale: Sale, i: number) => {
                return (
                  <ItemSaleData market={currentMarket} sale={sale} key={i} />
                );
              })}
              <PaginationControls
                changePage={changePage}
                currentPage={currentPage}
                totalPages={Math.ceil(currentSales.length / pageSize)}
              />
            </>
          ) : (
            <tr>
              <td
                colSpan={8}
                className="py-4 text-gray-600 text-center font-bold bg-gray-50"
              >
                No Sales Data Available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Container>
  );
};

export const ItemSaleData: React.FC<{
  sale: Sale;
  market?: "mint" | "order";
}> = ({ sale, market }) => {
  const { getCreator, creators } = useAnalyticsData();

  useEffect(() => {
    const getSalePartyDetails = async (type: "buyer" | "seller") => {
      switch (type) {
        case "buyer":
          await getCreator(sale.buyer);
          break;
        case "seller":
          await getCreator(sale.seller);
          break;
      }
    };

    if (!creators[sale.buyer]) {
      getSalePartyDetails("buyer");
    }

    if (!creators[sale.seller]) {
      getSalePartyDetails("seller");
    }
  }, [sale]);

  return (
    <tr className="w-full odd:bg-gray-50">
      <td className="border-t text-center align-middle">
        <a
          className="underline text-[#FF3F55]"
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          href={`https://www.polygonscan.com/tx/${sale.txHash}`}
        >
          {sale.txHash ? abridgeId(sale.txHash, 15) : "N/A"}
        </a>
      </td>
      <td className="border-t text-center py-3 justify-center min-h-[60px]">
        {sale.item.metadata.wearable.name}-{sale.item.id.split("-")[1]}
      </td>
      <td className="border-t text-center align-middle">
        {moment(new Date(parseInt(sale.timestamp) * 1000)).format("MM/DD/YY")}
      </td>
      <td className="border-t text-center align-middle">
        <a
          className="underline text-[#FF3F55]"
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          href={`https://www.polygonscan.com/address/${
            creators[sale.seller]?.avatars[0]?.userId
          }`}
        >
          {creators[sale.seller]?.avatars[0]?.userId
            ? abridgeId(
                creators[sale.seller]?.avatars[0]?.unclaimedName != ""
                  ? creators[sale.seller]?.avatars[0]?.unclaimedName
                  : creators[sale.seller]?.avatars[0]?.name,
                15
              )
            : "N/A"}
        </a>
      </td>
      <td className="border-t text-center align-middle">
        <a
          className="underline text-[#FF3F55]"
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          href={`https://www.polygonscan.com/address/${
            creators[sale.buyer]?.avatars[0]?.userId
          }`}
        >
          {creators[sale.buyer]?.avatars[0]?.userId
            ? abridgeId(
                creators[sale.buyer]?.avatars[0]?.unclaimedName != ""
                  ? creators[sale.buyer]?.avatars[0]?.unclaimedName
                  : creators[sale.buyer]?.avatars[0]?.name,
                15
              )
            : "N/A"}
        </a>
      </td>
      <td className="border-t text-center align-middle">
        {convertToMana(sale.price)} MANA
      </td>
      {market === "order" ? (
        <>
          <td className="border-t text-center align-middle">
            {(convertToMana(sale.price) * 0.025).toFixed(3)} MANA
          </td>
          <td className="border-t text-center align-middle">{sale.type}</td>
        </>
      ) : null}
    </tr>
  );
};
