import { useState } from "react";

export const usePage = (
  pageSize: number = 10,
  items: number,
  startingPage: number = 1
) => {
  const [currentPage, setCurrentPage] = useState(startingPage);

  const changePage = (direction: "previous" | "next" | "reset") => {
    switch (direction) {
      case "previous":
        if (currentPage > 1) {
          setCurrentPage((currentPage) => currentPage - 1);
        }
        break;
      case "next":
        if (currentPage < items / pageSize) {
          setCurrentPage((currentPage) => currentPage + 1);
        }
        break;
      case "reset":
        setCurrentPage(startingPage);
    }
  };

  const totalPages = Math.ceil(items / pageSize);

  return { currentPage, changePage, totalPages, pageSize };
};
