import React from "react";

// Packages
import { Outlet } from "react-router-dom";

// CSS Reset + Class Extensions
import "./styles/index.css";

// Components
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";

function App() {
  return (
    <SiteContainer>
      <Header />
      <div className="w-full">
        <Outlet />
      </div>
      <Footer />
    </SiteContainer>
  );
}

export default App;

// Global Site Wrapper ( Provides Base Line Styles and Alignment to Page)
const SiteContainer: React.FC = ({ children }) => {
  return (
    <div className="w-full px-10 max-w-7xl py-5 flex flex-col items-center">
      {children}
    </div>
  );
};
