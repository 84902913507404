import React from "react";

// Contexts
import { useAnalyticsData } from "../../../contexts/AnalyticsData/AnalyticsProvider";

// Shared Components
import Container from "../../shared/Container";
import CollectionsPerCreator from "../../shared/Graphs/CollectionsPerCreator";
import { Loader } from "../../shared/LoadingWrapper/LoadingWrapper";
import DataSnapshot from "./DataSnapshot";
import PaginatedCreators from "./PaginatedCreators";

// GLOBAL ANALYTICS VIEW
// Provide users with a clear and digestible snapshot of major market data

const Home: React.FC = () => {
  const { collections } = useAnalyticsData();

  return (
    <Container
      additionalClasses={["my-5 p-5 pb-10 shadow-lg"]}
      width="1"
      direction="col"
      items="center"
      border
    >
      {collections.loading ? (
        <Loader />
      ) : (
        <>
          <DataSnapshot />
          <CollectionsPerCreator />
          {/* <PaginatedCreators /> */}
        </>
      )}
    </Container>
  );
};

export default Home;
