import React from "react";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";

interface ToggleProps {
  on: boolean;
  onClick: Function;
  onTitle: string;
  offTitle: string;
  title?: string;
}

const ToggleSwitch: React.FC<ToggleProps> = ({
  on,
  onClick,
  onTitle,
  offTitle,
}) => {
  return (
    <div className="flex flex-row items-center mx-2">
      <div className="flex flex-row items-center justify-center ">
        <p className={`text-sm mr-1 ${on && "text-site-primary font-bold"}`}>
          {onTitle}
        </p>
        {on ? (
          <BiToggleLeft
            onClick={() => onClick()}
            size={40}
            className="text-site-primary cursor-pointer"
          />
        ) : (
          <BiToggleRight
            onClick={() => onClick()}
            size={40}
            className="text-site-primary cursor-pointer"
          />
        )}
        <p className={`text-sm ml-1 ${!on && "text-site-primary font-bold"}`}>
          {offTitle}
        </p>
      </div>
    </div>
  );
};

export default ToggleSwitch;
