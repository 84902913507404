import React, { useState } from "react";

// Packages
import { useNavigate } from "react-router-dom";

// Shared components
import Container from "../Container";
import SiteLogo from "../SiteLogo";
import SiteNavigation from "../SiteNavigation";
import TextInput from "../TextInput";
import ToggleSwitch from "../ToggleSwitch";

type SearchType = "creator" | "collection";

const Header: React.FC = () => {
  const [currentSearchString, setCurrentSearchString] = useState<string>("");

  const [currentSearchType, setCurrentSearchType] =
    useState<SearchType>("creator");

  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleSearchRequest = (type: SearchType, address: string) => {
    switch (type) {
      case "creator":
        navigate(`/creators/${address}`);
        break;
      case "collection":
        navigate(`/collections/${address}`);
        break;
      default:
        setError(true);
        break;
    }
  };

  const toggleSearchType = () => {
    if (currentSearchType === "collection") {
      setCurrentSearchType("creator");
      return;
    }
    setCurrentSearchType("collection");
  };

  return (
    <Container additionalClasses={["border-b"]} width="1">
      <SiteLogo />
      <SiteNavigation />
      <div className="flex flex-col items-center w-full">
        <ToggleSwitch
          on={currentSearchType === "creator"}
          onTitle="Creator"
          offTitle="Collection"
          onClick={() => toggleSearchType()}
        />
        <TextInput
          width="1"
          placeholder="Search Address..."
          setState={setCurrentSearchString}
          onEnter={() =>
            handleSearchRequest(currentSearchType, currentSearchString)
          }
          error={error}
        />
      </div>
    </Container>
  );
};

export default Header;
