import React, { useEffect, useMemo, useState } from "react";

// Packages
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

// Contexts
import { useAnalyticsData } from "../../../contexts/AnalyticsData/AnalyticsProvider";

// Shared
import Container from "../../shared/Container";

// utilities
import {
  abridgeId,
  paginate,
  uniqueCollectionsPerCreator,
  uniqueCreatorAddresses,
} from "../../../utilities/dataHelpers";

// Models
import { Width } from "../../../models/Shared";
import Collections from "../Collections/Collections";

export interface PaginatedProps {
  pageSize?: number;
  startingPage?: number;
  width?: Width;
  noItems?: boolean;
  collapsable?: boolean;
}

type SortType = "name" | "collections" | "items" | "none";

const PaginatedCreators: React.FC<PaginatedProps> = ({
  pageSize = 6,
  startingPage = 1,
  width = "1",
  noItems = false,
  collapsable,
}) => {
  const { collections, creators, getCreator } = useAnalyticsData();

  const uniqueCreators = uniqueCreatorAddresses(collections.data?.collections);

  const [currentPage, setCurrentPage] = useState(startingPage);

  const currentCreators = paginate(uniqueCreators, pageSize, currentPage);

  const changePage = (direction: "previous" | "next") => {
    switch (direction) {
      case "previous":
        if (currentPage > 1) {
          setCurrentPage((currentPage) => currentPage - 1);
        }
        break;
      case "next":
        if (currentPage < uniqueCreators.length / pageSize) {
          setCurrentPage((currentPage) => currentPage + 1);
        }
        break;
    }
  };

  return (
    <Container
      width={width}
      items="center"
      justify="start"
      title="Creators"
      direction="col"
      collapsable={collapsable}
    >
      <>
        <table className="w-full border rounded-sm mt-2 table-auto">
          <thead>
            <tr className="border font-bold">
              <th className="py-3">Creator Name</th>
              <th>Address</th>
              <th className="px-2">Collections</th>
              {!noItems && <th>Items</th>}
            </tr>
          </thead>
          <tbody>
            {currentCreators.map((creator: string) => {
              return (
                <CreatorData
                  noItems={noItems}
                  key={creator}
                  creator={creator}
                  collectionRow
                />
              );
            })}
          </tbody>
        </table>
        <Container direction="row" items="center" width="1">
          <BsChevronLeft
            className="p-1 m-1 text-3xl font-bold cursor-pointer border rounded-sm hover:bg-[#FF3F55] hover:text-white"
            onClick={() => changePage("previous")}
          />
          <p className="text-sm">
            {currentPage} / {Math.ceil(uniqueCreators.length / pageSize)}
          </p>
          <BsChevronRight
            className="p-1 m-1 text-3xl font-bold cursor-pointer border rounded-sm hover:bg-[#FF3F55] hover:text-white"
            onClick={() => changePage("next")}
          />
        </Container>
      </>
    </Container>
  );
};

export default PaginatedCreators;

export const CreatorData: React.FC<CreatorDataProps> = ({
  creator,
  noItems,
  collectionRow,
  maxAdressLen,
}) => {
  const { collections, getCreator, creators } = useAnalyticsData();

  const [collectionCount, setCollectionCount] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getInfo = async () => {
      await getCreator(creator);
    };
    getInfo();
  }, []);

  useEffect(() => {
    if (!collectionCount && !itemsCount) {
      const collectionInfo = uniqueCollectionsPerCreator(
        collections.data.collections
      ).creatorTotals[creator];
      setCollectionCount(collectionInfo.collectionCount);
      setItemsCount(collectionInfo.itemsCount);
    }
  }, [collectionCount, itemsCount]);

  return (
    <tr
      onClick={() => navigate(`/creators/${creator}`)}
      className={`w-full odd:bg-gray-50 hover:bg-red-50 ${
        id === creator ? "bg-red-50" : null
      } cursor-pointer`}
    >
      <td className="border-t items-center py-3 px-10 justify-between flex flex-row min-h-[60px]">
        {creators[creator]?.avatars?.length && !noItems ? (
          <img
            alt="Avatar"
            className="h-10 w-10"
            src={creators[creator]?.avatars[0]?.avatar?.snapshots?.face256}
          />
        ) : null}
        <p>
          {creators[creator]?.avatars?.length
            ? creators[creator]?.avatars[0]?.name
              ? creators[creator]?.avatars[0]?.name
              : "Not Registered"
            : "Not Registerd"}
        </p>
      </td>
      <td className="border-t text-center align-middle px-2">
        <a
          className="underline text-[#FF3F55]"
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          href={`https://www.polygonscan.com/address/${creators[creator]?.avatars[0]?.userId}`}
        >
          {noItems ? abridgeId(creator, 8) : abridgeId(creator, maxAdressLen)}
        </a>
      </td>
      {collectionRow && (
        <td className="border-t text-center align-middle px-2">
          {collectionCount}
        </td>
      )}
      {!noItems && (
        <td className="border-t text-center align-middle px-2">{itemsCount}</td>
      )}
    </tr>
  );
};

type CreatorDataProps = {
  creator: string;
  noItems: boolean;
  collectionRow: boolean;
  maxAdressLen?: number;
};
