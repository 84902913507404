import React, { useEffect, useState } from "react";

// Packages
import { useParams } from "react-router-dom";
import {
  TiArrowUnsorted,
  TiArrowSortedDown,
  TiArrowSortedUp,
} from "react-icons/ti";

// Contexts
import { useAnalyticsData } from "../../../contexts/AnalyticsData/AnalyticsProvider";

// Shard Components
import Container from "../../shared/Container";
import Collections from "../Collections/Collections";

// Models
import { Collection, Creator, Item, Sale } from "../../../models/DataContext";
import {
  convertToMana,
  filterMarket,
  onlyUnique,
  paginate,
  sumArray,
  uniqueCollectionsPerCreator,
} from "../../../utilities/dataHelpers";
import { useQuery } from "@apollo/client";
import { GET_CREATOR_SALES } from "../../../api/graphql/decentraland";
import { ItemSaleData } from "../Collections/Collection";
import { usePage } from "../../../utilities/hooks";
import { Loader } from "../../shared/LoadingWrapper/LoadingWrapper";
import KeyValueDisplay from "../../shared/KeyValueDisplay";
import ToggleSwitch from "../../shared/ToggleSwitch";
import PaginationControls from "../../shared/PaginationControls";
import DownloadCSV from "../../shared/DownloadCSV/DownloadCSV";

const Creators = () => {
  const { creators, getCreator } = useAnalyticsData();

  const { id } = useParams();

  const currentCreator = id ? creators[id] : undefined;

  useEffect(() => {
    if (!currentCreator && id) {
      getCreator(id);
    }
  }, [currentCreator, id, getCreator]);

  if (!currentCreator?.avatars.length) {
    return <Loader />;
  }

  return (
    <Container width="1" justify="start">
      <Container direction="col" width="1" justify="start">
        {currentCreator && currentCreator?.avatars?.length > 0 ? (
          <Container width="1" shadow>
            <CreatorProfile creator={currentCreator} />
          </Container>
        ) : (
          <></>
        )}
        <Collections creator={currentCreator} pageSize={10} />
        <CreatorSalesData creator={currentCreator} />
      </Container>
    </Container>
  );
};

export default Creators;

const CreatorProfile: React.FC<{ creator: Creator }> = ({ creator }) => {
  const { data, loading } = useQuery(GET_CREATOR_SALES, {
    variables: { sellerid: creator?.avatars[0]?.userId },
  });

  const marketSales = {
    primary: !loading
      ? sumArray(
          data.sales
            .filter((sale: Sale) => sale.type === "mint")
            .map((sale: Sale) => convertToMana(sale.price))
        )
      : 0,
    secondary: !loading
      ? sumArray(
          data.sales
            .filter((sale: Sale) => sale.type === "order")
            .map((sale: Sale) => convertToMana(sale.price))
        )
      : 0,
  };

  const { collections } = useAnalyticsData();

  const [collectionCount, setCollectionCount] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);

  useEffect(() => {
    if (!collectionCount && !itemsCount && !collections.loading) {
      const collectionInfo = uniqueCollectionsPerCreator(
        collections.data.collections
      ).creatorTotals[creator.avatars[0].userId];
      setCollectionCount(collectionInfo?.collectionCount);
      setItemsCount(collectionInfo?.itemsCount);
    }
  }, [collectionCount, itemsCount, collections.loading]);

  const additionalCreatorInfo =
    collectionCount > 0
      ? {
          primarySales: marketSales.primary.toFixed(2),
          secondarySales: marketSales.secondary.toFixed(2),
          totalCreatorRoyalties: (marketSales.secondary * 0.025).toFixed(2),
          collections: collectionCount,
          items: itemsCount,
        }
      : {};

  return (
    <Container width="1" title={creator.avatars[0].name} direction="col">
      <Container width="1" justify="start">
        <img
          src={creator.avatars[0].avatar.snapshots.body}
          alt={creator.avatars[0].name}
          className="mr-2 border"
        />
        <ul className="h-full flex flex-col justify-start ml-2">
          {
            <KeyValueDisplay
              data={{
                ...additionalCreatorInfo,
                ...creator.avatars[0],
              }}
              hiddenDataPoints={hiddenDataPoints}
            />
          }
        </ul>
      </Container>
    </Container>
  );
};

const CreatorSalesData: React.FC<{ creator: Creator | undefined }> = ({
  creator,
}) => {
  const { data, loading, error } = useQuery(GET_CREATOR_SALES, {
    variables: { sellerid: creator?.avatars[0]?.userId },
  });

  const [selectedCollection, setSelectedCollection] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [sort, setSort] = useState<"timestamp" | "item" | "price">("timestamp");
  const [sortOrder, setSortOrder] = useState<boolean>(true);

  const sortHandler = (sortBy: "timestamp" | "item" | "price") => {
    if (sort == sortBy) {
      return setSortOrder(!sortOrder);
    }
    setSort(sortBy);
    setSortOrder(true);
  };
  const collections = !loading
    ? data.sales.map((sale: Sale) => sale.item.collection)
    : [];

  const [currentMarket, setCurrentMarket] = useState<"mint" | "order">("mint");

  const marketSales = !loading ? filterMarket(data?.sales, currentMarket) : [];

  const itemSales =
    !loading && selectedItem
      ? marketSales.filter((sale: Sale) => sale.item.id === selectedItem)
      : marketSales;

  const items =
    !loading && selectedCollection
      ? data.sales
          .map((sale: Sale) => sale.item)
          .filter(
            (item: Sale["item"]) => item.collection.id === selectedCollection
          )
          .filter(onlyUnique)
      : [];

  let currentSales =
    !loading && selectedCollection
      ? itemSales.filter(
          (sale: Sale) => sale.item.collection.id === selectedCollection
        )
      : marketSales;

  currentSales = currentSales.sort((a: any, b: any) => {
    if (sort != "item") {
      if (sortOrder) return b[sort] - a[sort];
      return a[sort] - b[sort];
    }
    if (sortOrder) {
      return b.item.metadata.wearable.name.toUpperCase().trim() <
        a.item.metadata.wearable.name.toUpperCase().trim()
        ? -1
        : 1;
    }
    return a.item.metadata.wearable.name.toUpperCase().trim() <
      b.item.metadata.wearable.name.toUpperCase().trim()
      ? -1
      : 1;
  });
  const { currentPage, changePage, totalPages } = usePage(
    10,
    currentSales?.length
  );

  const paginatedSales = !loading && paginate(currentSales, 10, currentPage);

  const SortArrows = ({
    sortBy,
  }: {
    sortBy: "timestamp" | "item" | "price";
  }): JSX.Element => {
    return sort != sortBy ? (
      <TiArrowUnsorted style={{ display: "inline" }} />
    ) : sortOrder ? (
      <TiArrowSortedDown
        style={{
          display: "inline",
        }}
      />
    ) : (
      <TiArrowSortedUp
        style={{
          display: "inline",
        }}
      />
    );
  };

  if (error) {
    return (
      <Container
        direction="col"
        items="center"
        title="Creator Sales Data"
        width="1"
      >
        <div>Creator Sales Not Found</div>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container
        direction="col"
        items="center"
        title="Creator Sales Data"
        width="1"
      >
        <Loader />
      </Container>
    );
  }
  return (
    <Container
      direction="col"
      items="center"
      title="Creator Sales Data"
      width="1"
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <ToggleSwitch
            onClick={() => {
              setCurrentMarket((prevMarket) =>
                prevMarket === "mint" ? "order" : "mint"
              );
            }}
            on={currentMarket === "mint"}
            onTitle={"Primary"}
            offTitle={"Secondary"}
          />
          <select
            onChange={(newValue) => {
              setSelectedCollection(newValue.target.value);
            }}
            className="border px-2 py-1 border-site-primary mx-2"
          >
            <option key="viewAll" value="">
              All Collections
            </option>
            {collections
              .filter(onlyUnique)
              .reverse()
              .map((collection: Collection, i: number) => {
                return (
                  <option key={i} value={collection.id}>
                    {collection.name}
                  </option>
                );
              })}
          </select>
          {selectedCollection && (
            <select
              onChange={(newValue) => {
                setSelectedItem(newValue.target.value);
              }}
              className="border px-2 py-1 border-site-primary mx-2"
            >
              <option key="viewAll" value="">
                All Items
              </option>
              {items.reverse().map((item: any, i: number) => {
                return (
                  <option key={i} value={item.id}>
                    {item.metadata.wearable.name}-{item.id.split("-")[1]}
                  </option>
                );
              })}
            </select>
          )}
        </div>
        {currentSales.length > 0 && (
          <DownloadCSV
            filename={`${currentMarket}_sales_${creator!.avatars[0].userId}`}
            data={
              currentSales && currentSales.length
                ? currentMarket === "order"
                  ? currentSales.map((sale: Sale) => {
                      const newSaleItem: any = Object.assign({}, sale);
                      newSaleItem.item = sale.item?.metadata.wearable.name;

                      return {
                        ...newSaleItem,
                        totalCreatorRoyalties: `${(
                          convertToMana(sale.price) * 0.025
                        ).toFixed(3)}`,
                      };
                    })
                  : currentSales
                : []
            }
          />
        )}
      </div>
      <table className="w-full border rounded-sm mt-2 table-auto">
        <thead>
          <tr className="border font-bold">
            <th style={{ width: "25%" }}>Tx #</th>
            <th
              className="py-3"
              onClick={() => sortHandler("item")}
              style={{
                color: sort == "item" ? "rgb(255 63 85 / 1)" : undefined,
                width: "25%",
              }}
            >
              Item Name <SortArrows sortBy="item" />
            </th>
            <th
              onClick={() => sortHandler("timestamp")}
              style={{
                color: sort == "timestamp" ? "rgb(255 63 85 / 1)" : undefined,
              }}
            >
              Date <SortArrows sortBy="timestamp" />
            </th>
            <th>Seller</th>
            <th>Buyer</th>
            <th
              onClick={() => sortHandler("price")}
              style={{
                color: sort == "price" ? "rgb(255 63 85 / 1)" : undefined,
              }}
            >
              Price <SortArrows sortBy="price" />
            </th>
            {currentMarket === "order" ? (
              <>
                <th>Royalties</th>
                <th>Type</th>
              </>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {paginatedSales && paginatedSales.length ? (
            <>
              {paginatedSales.map((sale: Sale, i: number) => {
                return (
                  <ItemSaleData market={currentMarket} sale={sale} key={i} />
                );
              })}
              <PaginationControls
                totalPages={totalPages}
                changePage={changePage}
                currentPage={currentPage}
              />
            </>
          ) : (
            <tr>
              <td
                colSpan={8}
                className="py-4 text-gray-600 text-center font-bold bg-gray-50"
              >
                No Sales Data Available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Container>
  );
};

const hiddenDataPoints = [
  "wearables",
  "avatar",
  "blocked",
  "muted",
  "ethAddress",
  "tutorialStep",
  "version",
  "interests",
  "unclaimedName",
];
