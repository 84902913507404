import { useQuery } from "@apollo/client";
import React, { useContext, createContext, useEffect, useState } from "react";

// Models
import { Creator, DataContextSchema } from "../../models/DataContext";

// API
import {
  GET_ALL_COLLECTIONS,
  GET_DATA_COUNTS,
} from "../../api/graphql/decentraland";
import { fetchCreatorInformation } from "../../api/http/decentraland";

const AnalyticsContext = createContext<DataContextSchema>(
  {} as DataContextSchema
);

const AnalyticsProvider: React.FC = ({ children }) => {
  const counts = useQuery(GET_DATA_COUNTS);
  const collections = useQuery(GET_ALL_COLLECTIONS);

  const [collectionsLoaded, setCollectionsLoaded] = useState(false);

  const [creators, setCreators] = useState<{ [key: string]: Creator }>({});

  const getCreator = async (creatorId: string): Promise<Creator> => {
    if (!Object.keys(creators).includes(creatorId)) {
      const info = await fetchCreatorInformation(creatorId);
      setCreators((creators) => {
        let newCreators = Object.assign({}, creators);
        newCreators[creatorId] = info;
        return newCreators;
      });
      return info;
    } else {
      return creators[creatorId];
    }
  };

  useEffect(() => {
    if (
      collections?.data?.collections?.length <
      counts?.data?.counts[0]?.collectionTotal
    ) {
      collections.fetchMore({
        variables: {
          skip: collections.data.collections.length,
        },
      });
    }
  });

  useEffect(() => {
    if (
      collections?.data?.collections?.length >=
      counts?.data?.counts[0]?.collectionTotal
    ) {
      setCollectionsLoaded(true);
    }
  }, [collections?.data?.collections?.length]);

  return (
    <AnalyticsContext.Provider
      value={{
        collections,
        counts,
        getCreator,
        creators,
        collectionsLoaded,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;

export const useAnalyticsData = () => {
  const analyticsData = useContext(AnalyticsContext);

  return analyticsData;
};
