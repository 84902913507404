import React, { useEffect, useRef, useState } from "react";

// Packages
import { Audio } from "react-loader-spinner";

// Shared Components
import Container from "../Container";

type LoadingProps = {
  loading: boolean;
  children: React.ReactChild | React.ReactChild[] | null;
};

const LoadingWrapper: React.FC<LoadingProps> = ({ loading, children }) => {
  if (loading) {
    return <Loader />;
  } else {
    return <>{children}</>;
  }
};

export default LoadingWrapper;

export const Loader: React.FC<{ timeoutInSeconds?: number; size?: number }> = ({
  timeoutInSeconds = 3,
  size = 60,
}) => {
  const [loaderTimedOut, setLoaderTimedOut] = useState(false);

  const timeoutRef = useRef(0);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setLoaderTimedOut(true);
    }, timeoutInSeconds * 1000);

    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <Container width="1" items="center" direction="row" shadow>
      {!loaderTimedOut ? (
        <Audio height={size} width={size} color="grey" ariaLabel="loading" />
      ) : (
        <p className="font-bold">Data Not Found</p>
      )}
    </Container>
  );
};
