import React from "react";
import ReactDOM from "react-dom";

// Apollo ( GraphQL )
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";

import App from "./App";

// Libraries
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import Home from "./components/Pages/Home";
import Collections from "./components/Pages/Collections/Collections";
import Collection from "./components/Pages/Collections/Collection";
import Creators from "./components/Pages/Creators/Creators";
import PaginatedCreators from "./components/Pages/Home/PaginatedCreators";

// Providers
import DataProvider from "./contexts/AnalyticsData/AnalyticsProvider";
import ToastProvider from "./contexts/Toast/Toast";

// Utilities
import { decentralandGraphUrl } from "./utilities/urls";
import { offsetLimitPagination } from "@apollo/client/utilities";

const decentralandClient: ApolloClient<NormalizedCacheObject> =
  new ApolloClient({
    uri: decentralandGraphUrl,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            collections: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: [],
              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing, incoming, { args: { skip = 0 } }: any) {
                // Slicing is necessary because the existing data is
                // immutable, and frozen in development.
                const merged = existing ? existing.slice(0) : [];
                for (let i = 0; i < incoming.length; ++i) {
                  merged[skip + i] = incoming[i];
                }
                return merged;
              },
            },
          },
        },
      },
    }),
  });

ReactDOM.render(
  <ApolloProvider client={decentralandClient}>
    <ToastProvider>
      <DataProvider>
        <Router>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Home />} />
              <Route
                path="/creators/"
                element={<PaginatedCreators pageSize={20} />}
              />
              <Route path="/creators/:id" element={<Creators />} />
              <Route
                path="/collections"
                element={<Collections pageSize={20} />}
              />
              <Route path="/collections/:id/" element={<Collection />} />
              <Route path="/collections/:id/:itemid" element={<Collection />} />
            </Route>
          </Routes>
        </Router>
      </DataProvider>
    </ToastProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
