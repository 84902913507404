import React from "react";
import {
  LinkProps,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

const SiteNavigation: React.FC = () => {
  return (
    <div className="w-full flex flex-row min-h-full items-center justify-center">
      {navLinks.map((navLink, i) => {
        return (
          <ActiveLInk key={i} to={navLink.to}>
            {navLink.title}
          </ActiveLInk>
        );
      })}
    </div>
  );
};

export default SiteNavigation;

const ActiveLInk = ({ children, to, ...props }: LinkProps) => {
  let navigate = useNavigate();
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div
      onClick={() => navigate(to)}
      className={`h-full flex flex-col items-center cursor-pointer justify-center hover:border-b-4 hover:text-[#FF3F55] hover:border-b-site-primary ${
        match ? "border-b-4 border-b-site-primary text-[#FF3F55]" : ""
      }`}
    >
      <p className={`mx-2`}>{children}</p>
    </div>
  );
};

const navLinks: NavLink[] = [
  { to: "/", title: "Home" },
  { to: "/creators", title: "Creators" },
  { to: "/collections", title: "Collections" },
];

type NavLink = {
  to: string;
  title: string;
};
